<template>
  <div>
    <body-with-header ads title="مدیریت همکاری و متخصصین">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>مدیریت متخصصان</span>
                  <font-awesome-icon icon="history" />
                </div>
              </template>
              <advicerManagementForm />
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>زمین های متخصصان</span>
                  <font-awesome-icon icon="users" />
                </div>
              </template>
              <farmerAdvicerFarmsForm />
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>توصیه های متخصصان</span>
                  <font-awesome-icon icon="user-md" />
                </div>
              </template>
              <advicerTipsForm />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>
  
  <script>
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import advicerManagementForm from "./advicerManagement/advicerManagementForm.vue";
import advicerTipsForm from "./advicerTips/advicerTipsForm.vue";
import farmerAdvicerFarmsForm from "./farms/farmerAdvicerFarmsForm.vue";
export default {
  components: {
    BodyWithHeader,
    advicerManagementForm,
    advicerTipsForm,
    farmerAdvicerFarmsForm,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال بارگذاری...",
    };
  },
};
</script>
  