<template>
  <div style="min-height:30dvh;">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات پروفایل تخصصی..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="advicerDetailInfo" v-if="farmUserDetail != null && !loading">
      <h5 class="advicerSubForm-title">جزئیات اطلاعات متخصص {{ advicerName }}</h5>
      <div class="detail-info">
        <h5>سابقه کار : {{ farmUserDetail.workExperience }} ماه</h5>
        <p>
          <i>متن معرفی متخصص</i>
          {{ farmUserDetail.text }}
        </p>
      </div>
      <div class="detail-expertise">
        <h5>تخصص های متخصص</h5>
        <h6 v-for="(ex, index) of farmUserDetail.expertise" :key="index">
          {{ ex.item1 }}
        </h6>
      </div>
    </div>
    <no-data v-else class="pt-md-5" :show="!loading" message="متخصص پروفایل تخصصی خود را تکمیل نکرده است"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import NoData from "../../../components/no-data.vue";

export default {
  components: {NoData},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      farmUserDetail: {
        workExperience: 0,
        text: "",
        expertise: [],
      },
    };
  },
  props: ["advicerId", "advicerName"],
  watch: {
    advicerId: function () {
      this.GetDetail();
    },
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", [
      "FarmerAdvicerDetailInfo",
    ]),

    async GetDetail() {
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerAdvicerDetailInfo({
        advicerId: this.advicerId,
      });
      if (
        result.data.expertise &&
        result.data.workExperience > 0 &&
        result.data.text
      ) {
        this.farmUserDetail = result.data;
      } else {
        this.farmUserDetail = null;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.GetDetail();
  },
  created() {
  },
};
</script>

<style scoped>
.advicerSubForm-title {

}

.advicerDetailInfo {

}

.detail-info {
  float: right;
  width: 60%;
  height: 36vh;
  margin: 0.7em;
  border-right: 1px solid green;
  padding-right: 1em;
}

.detail-info span {
  float: right;
  width: 100%;
  background-color: #0dff0d;
  color: #373737;
}

.detail-info span h5 {
  float: right;
  font-family: "Dirooz";
  padding: 0.3em 1em 0em 0em;
  font-size: 1em;
  font-weight: bold;
}

.detail-info span h6 {
  float: right;
}

.detail-info p {
  float: right;
}

.detail-expertise {
  float: right;
  width: 37%;
  height: 37vh;
  background-color: whitesmoke;
}

.detail-expertise h5 {
  float: right;
  width: 91%;
  margin: 1em;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
}

.detail-expertise h6 {
  float: right;
  width: 96%;
  margin: 0.4em;
  border-right: 2px solid green;
  padding-right: 0.3em;
  padding: 0.2em 0.3em;
  background-color: #fff;
}
</style>
