<template>
  <div class="tab-body-card" style="min-height: calc(100vh - 10vh)">
    <vSelect
      dir="rtl"
      v-model="selectedAdvicer"
      :options="advicersOptions"
      placeholder="انتخاب متخصص..."
      label="label"
      track-by="value"
    ></vSelect>
    <tipsList :userId="selectedAdvicer.value" />
  </div>
</template>
  
  <script>
import tipsList from "./subForm/tipsList";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions } from "vuex";
export default {
  components: {
    tipsList,
    vSelect,
  },
  data() {
    return {
      selectedAdvicer: { value: "0", label: "تمام متخصص ها" },
      advicersOptions: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", ["GetAllFarmerAdvicer"]),

    async GetAdvicerFilter() {
      let result = await this.GetAllFarmerAdvicer({
        searchName: "",
      });
      this.advicersOptions.push({ value: "0", label: "تمام متخصص ها" });
      result.data.forEach((element) => {
        this.advicersOptions.push({
          value: element.advicerId,
          label: `${element.fullName} (${element.phone}) تعداد زمین : ${element.farmCount} عدد`,
        });
      });
    },
  },
  mounted() {
    this.GetAdvicerFilter();
  },
  created() {},
};
</script>
  
  <style>
</style>