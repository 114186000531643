<template>
  <div class="tab-body-card" style="min-height: calc(100vh - 10vh)">
    <div
      v-if="advicerList.length > 0"
      style="
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
      "
    >
      <div
        class="advicer-item-box"
        v-for="(item, index) of advicerList"
        :key="index"
      >
        <ul>
          <li>
            <h4>نام و نام خانوادگی :</h4>
            <h3>{{ item.fullName }}</h3>
          </li>
          <li>
            <h4>تلفن همراه :</h4>
            <h3>{{ item.phone }}</h3>
          </li>
          <li>
            <h4>استان(شهرستان) :</h4>
            <h3>{{ item.stateCity }}</h3>
          </li>
          <li>
            <h4>تاریخ تولد :</h4>
            <h3>{{ item.birthDate }}</h3>
          </li>
          <li>
            <h4>تحصیلات :</h4>
            <h3>{{ item.educatuin }}</h3>
          </li>
          <li>
            <h4>تعداد زمین :</h4>
            <h3>{{ `${item.farmCount} عدد زمین` }}</h3>
          </li>
        </ul>
        <div class="advicer-action">
          <button
            @click="
              GetSubForm(
                item.advicerId,
                item.fullName,
                'advicerDetail',
                'پروفایل متخصص'
              )
            "
            class="bg-info"
          >
            پروفایل تخصصی
          </button>
          <button
            @click="
              GetSubForm(
                item.advicerId,
                item.fullName,
                'advicerTips',
                'آخرین دستورالعمل متخصص'
              )
            "
            class="bg-info"
          >
            آخرین دستورالعمل
          </button>
          <button
            @click="
              GetSubForm(
                item.advicerId,
                item.fullName,
                'advicerFarms',
                'لیست زمین های متخصص'
              )
            "
            class="bg-info"
          >
            لیست زمین ها
          </button>
          <button
            @click="DeleteAdvicer(item.advicerId, item.fullName)"
            class="bg-danger"
          >
            اتمام همکاری
          </button>
        </div>
      </div>
    </div>
    <no-data v-else show message="هیچ متخصصی ثبت نشده است" />
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      modal-style="max-width: 50dvw !important; height:60dvh;"
    >
      <component
        v-bind:is="subForm"
        :advicerId="advicerId"
        :advicerName="advicerName"
      />
    </VueModal>
  </div>
</template>

<script>
import advicerFarms from "./advicerFarms.vue";
import advicerDetail from "./advicerDetail.vue";
import advicerTips from "./advicerTips.vue";
import { mapActions } from "vuex";
import KeyValue from "../../../components/keyValue.vue";
import NoData from "../../../components/no-data.vue";

export default {
  components: {
    NoData,
    KeyValue,
    advicerFarms,
    advicerDetail,
    advicerTips,
  },
  data() {
    return {
      advicerList: [],
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      loading: false,
      advicerId: 0,
      advicerName: "",
      loadingText: "در حال دریافت اطلاعات ...",
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", [
      "GetAllFarmerAdvicer",
      "FarmerAdvicerDelete",
    ]),

    async GetAll() {
      this.advicerList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.GetAllFarmerAdvicer({ searchName: "" });
      if (result.data) {
        this.advicerList = result.data;
      }
      this.loading = false;
    },
    GetSubForm(advicerId, advicerName, subForm, title = "") {
      this.advicerId = advicerId;
      this.advicerName = advicerName;
      this.subForm = subForm;
      this.modalTitle = title;
      this.modelShowState = true;
    },
    async DeleteAdvicer(advicerId, advicerName) {
      this.$confirm({
        message: `با متخصص ${advicerName} قطع همکاری میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال حذف متخصص...";
            let result = await this.FarmerAdvicerDelete({
              advicerId: advicerId,
            });
            if (result.succeeded) {
              this.$notify({
                title: "پیام سیستم",
                text: `متخصص از لیست همکاران حذف شد`,
                type: "success",
              });
              await this.GetAll();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message}`,
                type: "error",
              });
            }
            this.loading = false;
          }
        },
      });
    },
  },
  mounted() {
    this.GetAll();
  },
  created() {},
};
</script>

<style scoped>
.advicer-item-box {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 10px;
  border: 1px solid #bbb;
  border-radius: 13px;
  padding: 10px;
  background-color: #fff;
  flex-grow: 1;
  max-width: 25vw;
  box-shadow: 0px 0px 14px -5px #000000;
}
.advicer-item-box ul {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  flex-grow: 1;
  list-style: none;
}
.advicer-item-box ul li {
  width: 94%;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  border-bottom: 1px dashed #d7d7d7;
  justify-content: flex-start;
  align-items: flex-end;
}
.advicer-item-box ul li:last-child {
  border: none;
}
.advicer-item-box ul li h4 {
  width: 100%;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.advicer-item-box ul li h3 {
  width: 100%;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  color: #607d8b;
}
.advicer-action {
  display: flex;
  flex-direction: column;
  width: 8vw;
  align-items: stretch;
  justify-content: flex-start;
}
.advicer-action button {
  border: none;
  cursor: pointer;
  margin: 2px;
  padding: 6px;
  border-radius: 15px;
}
</style>
